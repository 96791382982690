<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="box clearfix">
        <user-left :name="'mytest'"></user-left>
        <div class="user-right" ref="right">
          <div class="title-top">
            <span>测评记录-{{title}}</span>
          </div>
          <div class="a-over-h search-sort">
            <div class="search a-fl">
              <div class="search-form a-fr a-over-h">
                <input class="a-fl" type="text" v-model="keywords" placeholder="请输入搜索关键词"/>
                <button class="a-fr icon-search" @click="searchFun"></button>
              </div>
            </div>
            <!--<div class="sort-btn a-fr">-->
            <!--<button title="升序排列" class="icon-ase" :class="{ active: sort === 'ase' }" @click="changeSort('ase')"></button>-->
            <!--<button title="降序排列" class="icon-dec" :class="{ active: sort === 'desc' }" @click="changeSort('desc')"></button>-->
            <!--</div>-->
          </div>
          <div class="derive">
            <p @click="batchExcel()">Excel导出(横版)</p>
            <p @click="batchExcel(2)">Excel导出(竖版)</p>
          </div>
          <div class="table-box">
            <table>
              <tr class="a-ta-c">
                <td><input type='checkbox' class='check' v-model='checked' v-on:click='checkedAll'></td>
                <!--<td class="tx-l">测评量表名称</td>-->
                <td style="text-align:left;">测评人</td>
                <td>测评时间</td>
                <td>测评状态</td>
                <td>操作</td>
              </tr>
              <tr v-if="td_case.length!=0" v-for="(item, index) in td_case" :key="index">
                <!--选择框start-->
                <td>
                  <input class="check" style="margin-left:50px" v-if="item.status==2" type="checkbox"
                         name="checkboxinput"
                         v-model="checkboxModel" :value="item.exam_id"/>
                </td>
                <!--选择框end-->
                <!--<td>-->
                <!--<div class="table-name a-e1">{{item.title}}</div>-->
                <!--</td>-->
                <td class="a-ta-c" style="text-align:left;">{{item.child_name}}</td>
                <td class="a-ta-c">{{item.create_time}}</td>
                <td>
                  <div style="width: 80px;text-align: center;margin:0 auto;">
                    <span v-if="item.status == 2">已完成</span>
                    <span v-else>测评中</span>
                  </div>
                </td>
                <td width="18%">
                  <div class="btn1-box">
                    <p><span v-if="item.status != 2" @click="seeResult(item)">继续</span></p>
                    <p><span v-if="item.status == 2" @click="seeResult(item)">查看详情</span></p>
                    <p><span v-if="item.status == 2" @click="fnPrint(item)">生成报告</span></p>
                    <!--                    <p><span v-if="item.status == 2" @click="printExcel(item.title_id,item.exam_id)">EXCEL报表</span></p>-->
                  </div>
                </td>
              </tr>
              <tr v-if="td_case.length==0">
                <td colspan="5" style="text-align: center">暂无数据</td>
              </tr>
            </table>
            <div class="btn-box"  v-if="td_case.length!=0">
              <div @click="moreCallback(0)">上一页</div>
              <div @click="moreCallback(1)" style="margin-right: 15px;">下一页</div>
              <span>共{{listInfo.totalPage}}页</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
    <div id="printBox"></div>
  </div>
</template>

<script>
    import HeaderNav from '@/components/HeaderNav'
    import FooterNav from '@/components/FooterNav'
    import MoreList from '@/components/MoreList'
    import UserLeft from '@/components/UserLeft'

    export default {
        name: 'user',
        data() {
            return {
                current: 'user',
                td_case: [],
                sort: 'desc',
                keywords: '',
                page: 1,
                more: false,
                nodata: false,
                checkboxModel: [],
                checked: false,
                checkLength: '',
                listInfo: {},
            }
        },
        methods: {
            searchFun: function () {
                this.getList()
            },
            changeSort: function (sort) {
                this.sort = sort
                this.page = 1
                this.getList()
            },
            checkedAll() {
                var _this = this
                if (this.checked) {//实现反选
                    _this.checkboxModel = [];
                } else {//实现全选
                    _this.checkboxModel = [];
                    _this.td_case.forEach(function (item) {
                        if (item.status == 2) {
                            _this.checkboxModel.push(item.exam_id);
                        }
                    });
                    _this.checkLength = _this.checkboxModel.length
                }
            },
            getList: function () {
                let params = {
                    token: localStorage.getItem('token'),
                    keyword: this.keywords,
                    // sort: this.sort,
                    page: 1,
                    limit: 10
                }
                if (this.$route.query.id) {
                    params.title_id = this.$route.query.id
                }
                this.$post('question/examHistory', params).then(res => {
                    if (res.code === 1) {
                        if (!res.data.totalCount) {
                            this.nodata = true
                            this.td_case = []
                            // return false
                        } else {
                            this.nodata = false
                        }
                        this.td_case = res.data.listData
                        this.listInfo = res.data
                        if (res.data.listData.length < res.data.perPage) {
                            this.more = false
                        } else {
                            this.more = true
                        }
                    } else {
                        this.$layer.msg(res.msg)
                        this.nodata = true
                    }

                }).catch(response => {
                    this.$layer.msg('网络异常，请稍后重试！')
                    this.nodata = true
                })
            },
            moreCallback: function (way) {
                if (way == 0) {
                    if (this.page <= 1) {
                        this.$layer.msg('已经是第一页了！')
                        return
                    } else {
                        this.page--
                    }
                } else if (way == 1) {
                    if (this.page >= parseInt(this.listInfo.totalPage)) {
                        this.$layer.msg("最后一页了")
                    } else {
                        this.page++
                    }
                }
                let params = {
                    token: localStorage.getItem('token'),
                    keyword: this.keywords,
                    sort: this.sort,
                    page: this.page,
                    limit: 10
                }

                if (this.$route.query.id) {
                    params.title_id = this.$route.query.id
                }
                this.$post('question/examHistory', params).then(res => {
                    if (res.code === 1) {
                        this.td_case = res.data.listData
                        this.listInfo = res.data

                    } else {
                        this.$layer.msg(res.msg)
                    }
                }).catch(response => {
                    this.$layer.msg('网络异常，请稍后重试！')
                })
            },
            seeResult: function (item) {
                if (item.position == 'word') {
                    this.$router.push('/words?eid=' + item.exam_id + "&id=" + item.title_id)
                } else {
                    if (item.status == 2) {
                        this.$router.push('/' + item.type + '?eid=' + item.exam_id + "&id=" + item.title_id)
                    } else {
                        localStorage.setItem('eid', item.exam_id)
                        localStorage.setItem('tid', item.title_id)
                        localStorage.setItem('qid', item.position)
                        this.$router.push('/test')
                    }
                }
            },
            printExcel(titleId, exId) {
                window.location.href = this.GLOBAL.WEB_SITE + '/api/question/examexport.html' + '?token=' + localStorage.getItem('token') + '&title_id=' + titleId + '&ids=' + exId
            },
            batchExcel(vertical) {
                let titleId = this.$route.query.id
                let arrIds = this.checkboxModel.join(',')
                if (arrIds === '') {
                    this.$layer.msg('请选择要导出的数据！')
                    return
                }
                console.log(arrIds, '选中的数组');
                if (vertical === 2) {
                    window.location.href = this.GLOBAL.WEB_SITE + '/api/question/examexport.html' + '?token=' + localStorage.getItem('token') + '&title_id=' + titleId + '&ids=' + arrIds + '&excel_style=2'
                } else {
                    window.location.href = this.GLOBAL.WEB_SITE + '/api/question/examexport.html' + '?token=' + localStorage.getItem('token') + '&title_id=' + titleId + '&ids=' + arrIds
                }
            },
            fnPrint: function (item) {
                if (!!window.ActiveXObject || "ActiveXObject" in window) {
                    let confirm = this.$refs.myComfirm
                    confirm.open('提示', {
                        content: 'IE浏览器无法使用此功能，请更换浏览器！',
                        btn: ['确定']
                    }, () => {
                        confirm.hide()
                    })
                    return false
                }
                if (document.getElementById('iframeId')) {
                    document.getElementById('iframeId').remove()
                }
                let printBox = document.getElementById('printBox')
                this.$layer.msg('导出中请稍后', {time: 4})
                let url = this.GLOBAL.WEB_SITE + '/#/' + item.type + '?eid=' + item.exam_id + '&id=' + item.title_id + '&print=1'
                // let url = 'localhost:8080/#/' + item.type + '?eid=' + item.exam_id + '&id=' + item.title_id + '&print=1'
                let iframe = document.createElement('iframe')
                iframe.style.cssText = 'width:0;height:0'
                iframe.id = 'iframeId'
                iframe.src = url
                printBox.appendChild(iframe)
            },
            getHistory() {

            },
        },
        watch: {
            keywords() {
                this.page = 1
                this.getList()
            },
            'checkboxModel': {
                handler: function (val, oldVal) {
                    if (this.checkboxModel.length === this.checkLength) {
                        this.checked = true;
                    } else {
                        this.checked = false;
                    }
                },
                deep: true
            }
        },
        components: {
            HeaderNav,
            FooterNav,
            MoreList,
            UserLeft
        },
        mounted: function () {
            var h = document.documentElement.clientHeight || document.body.clientHeight
            this.$refs.content.style.minHeight = h - 124 + 'px'
            this.$refs.right.style.minHeight = h - 144 + 'px'
        },
        created() {
            this.title = this.$route.query.title
            this.getList()
        }
    }
</script>

<style lang="less" scoped>
  @import url('../assets/css/page/history');

  .btn1-box {
    min-width: 60px;
    text-align: center;
  }

  .btn1-box span {
    color: #f39902;
    padding: 0 5px;
    cursor: pointer;
  }

  .btn1-box span:hover {
    text-decoration: underline;
  }

  .btn-box {
    text-align: right;
    margin: 60px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #f39902;
  }

  .btn-box div {
    color: #f39902;
    font-size: 16px;
    border-radius: 4px;
    margin: 0 5px;
    cursor: pointer;
    outline: medium;
  }

  .btn-box div:last-child {
    /*color:#333;*/
    margin-right: 10px;
  }
</style>
